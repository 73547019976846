import React from 'react';
import { MenuLayout } from '../../components/menu-layout.component';
import { Footer } from '../../components/footer.component';

export default function PrivacyPolicy() {
    return (
        <MenuLayout disableSecureLayout={true} isAbs={true} className="homepage transparent">
            <section className="docs">
                <div className="container">
                    <h1>豊泉家PCR検査センター</h1>
                    <h2 className="t-heading1 docs__heading t-center">プライバシーポリシー</h2>
                    <div className="docs__block">
                        <p>一般財団法人 SF豊泉家（以下，「当法人」といいます）は、「自立・自由度の高い福祉で社会に貢献する」を基本理念として、当法人が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、個人の権利の保護、個人情報に関する法規制等を遵守いたします。また、個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全法人を挙げて取り組むことをここに宣言いたします。</p>
                        <p>当法人は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</p>
                        <p>第１条（個人情報）</p>
                        <p>「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</p>
                        <p>第２条（個人情報の収集方法）</p>
                        <p>当法人は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，配送先情報，銀行口座番号，クレジットカード番号，運転免許証番号、健康保険証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当法人の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。</p>
                        <p>第３条（個人情報を収集・利用する目的）</p>
                        <p>当法人が個人情報を収集・利用する目的は，以下のとおりです。</p>
                        <ol>
                            <li>当法人サービスの提供・運営のため</li>
                            <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
                            <li>ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当法人が提供する他のサービスの案内のメールを送付するため</li>
                            <li>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
                            <li>利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</li>
                            <li>ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</li>
                            <li>有料サービスにおいて，ユーザーに利用料金を請求するため</li>
                            <li>上記の利用目的に付随する目的</li>
                        </ol>
                        <p>第４条（利用目的の変更）</p>
                        <ol>
                            <li>当法人は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。</li>
                            <li>利用目的の変更を行った場合には，変更後の目的について，当法人所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。</li>
                        </ol>
                        <p>第５条（個人情報の第三者提供）</p>
                        <ol>
                            <li>当法人は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
                        <ol>
                                    <li>人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</li>
                                    <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき</li>
                                    <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                                    <li>予め次の事項を告知あるいは公表し，かつ当法人が個人情報保護委員会に届出をしたとき
                        <ol>
                                            <li>利用目的に第三者への提供を含むこと</li>
                                            <li>第三者に提供されるデータの項目</li>
                                            <li>第三者への提供の手段または方法</li>
                                            <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
                                            <li>本人の求めを受け付ける方法</li>
                                        </ol>
                                    </li>
                                    <li>前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
                        <ol>
                                            <li>当法人が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
                                            <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                                            <li>個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <p>第６条（個人情報の開示）</p>
                        <ol>
                            <li>当法人は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。
                        <ol>
                                    <li>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</li>
                                    <li>当法人の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                                    <li>その他法令に違反することとなる場合</li>
                                </ol>
                            </li>
                            <li>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</li>
                        </ol>
                        <p>第７条（個人情報の訂正および削除）</p>
                        <ol>
                            <li>ユーザーは，当法人の保有する自己の個人情報が誤った情報である場合には，当法人が定める手続きにより，当法人に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。</li>
                            <li>当法人は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。</li>
                            <li>当法人は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。</li>
                        </ol>
                        <p>第８条（個人情報の利用停止等）</p>
                        <ol>
                            <li>当法人は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。</li>
                            <li>前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。</li>
                            <li>当法人は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。</li>
                            <li>前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。</li>
                        </ol>
                        <p>第９条（セキュリティ）</p>
                        <ol>
                            <li>本ウェブサイトは、個人情報を保護するためにSSLに対応しています。SSLとは「Secure Socket Layer」の略で、SSLはウェブサーバーとウェブブラウザーとの間に暗号化し送受信できる通信方法です。セキュリティ機能に対応したブラウザを使用することで、入力される氏名や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、万が一、送受信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。</li>
                            <li>本ウェブサイトにアクセスする際には、IPアドレスというインターネット上のサーバーをそれぞれ区別するためのIPアドレス（数字）がログデータとして自動的に記録されます。これらは、本サービスが置かれたサーバーの機能を正常に保つ上で取得・記録が必要とされているものです。本ウェブサイトで記録されたIPアドレス、ドメイン名のデータが外部の第三者に提供されることはありません。</li>
                            <li>クッキー（Cookie）とは、利用者が本ウェブサイトをご覧になったという情報を、その利用者のPC、スマートフォン、タブレットなどのインターネット接続可能な機器内に記憶させておく機能のことです。クッキーを利用することによりご利用のPCのウェブサイト訪問回数や訪問したページなどの情報を取得することができます。なお、クッキーを通じて収集する情報には当法人が「個人を識別できる情報」は一切含まれておりません。ブラウザの設定によりクッキーの機能を無効にすることもできます。クッキーの機能を無効にしても当ウェブサイトのご利用には問題ありません。</li>
                        </ol>
                        <p>第１０条（プライバシーポリシーの変更）</p>
                        <ol>
                            <li>本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</li>
                            <li>当法人が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</li>
                        </ol>
                        <p>第１１条（お問い合わせ窓口）</p>
                        <p>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</p>
                        <p>住所：〒561-0881<br /> 大阪府豊中市中桜塚3-2-1</p>
                        <p>法人名：一般財団法人 SF豊泉家</p>
                        <p>担当部署：豊泉家PCR検査センター</p>
                        <p>Eメールアドレス：pcr-center@e-housenka.com</p>
                    </div>
                </div>
            </section>
            <Footer />
        </MenuLayout>
    );
}
